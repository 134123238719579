<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="0" tab="QS世界大学排名">
      <table-page :config="configQS" />
    </a-tab-pane>
    <a-tab-pane key="1" tab="THE世界大学排名">
      <table-page :config="configTHE" />
    </a-tab-pane>
    <a-tab-pane key="3" tab="USNEWS美国大学排名">
      <table-page :config="configUSNEWS" />
    </a-tab-pane>
    <a-tab-pane key="4" tab="TIMES英国大学排名">
      <table-page :config="configTIMES" />
    </a-tab-pane>

  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
import { strategys, informations } from '@/utils/public';

export default {
  name: 'strategyInfoDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: '0',

      configQS: {
        dataUrl: `/ranking/list?rankingId=1`,
        filter: {
          controls: [
            {
              key: 'schoolName',
              label: '学校名称',
              type: 'text'
            },
          ]
        },
        columns: [
          {
            key: 'schoolName',
            title: '学校名',
            width: '130px',
          },
          {
            key: 'sort',
            title: '排名',
            width: '150px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '70px'
          }
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'add',
            text: '编辑介绍',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/desc/update',
            config: {
              color: 'primary',
              title: '编辑介绍',
              initUrl: 'ranking/desc/get?id=1',
              submitUrl: '/ranking/desc/update',
              submitText: '确认',
              controls: [
                {
                  key: 'id',
                  label: '排名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '1',
                  config: {
                    options: [
                      {
                        value: '1',
                        text: 'QS世界大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'logo',
                  label: 'logo',
                  type: 'file',
                  required: true,
                  config: {
                    uploadUrl: '/api/school/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 500
                  }
                },
                {},
                {
                  key: 'rankingName',
                  label: '排名名称',
                  type: 'text',
                  required: true,
                },
                {},
                {
                  key: 'rankingIntroduction',
                  label: '排名简介',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 800, message: '请尽量精简内容，输入800字以内' }
                    ]
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'add',
            text: '新增排名',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/sort/add',
            config: {
              color: 'primary',
              title: '新增排名',
              initUrl: '/ranking/sort/getSchoolList',
              submitUrl: '/ranking/sort/add',
              submitText: '保存',
              controls: [
                {
                  key: 'rankingId',
                  label: '排名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '1',
                  config: {
                    options: [
                      {
                        value: '1',
                        text: 'QS世界大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'collegeId',
                  label: '学校名',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'data',
                    textKey: 'schoolName',
                    valueKey: 'id'
                  }
                },
                {},
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  required: true,
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'add',
            text: '编辑',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/sort/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: '/ranking/sort/get',
              submitUrl: '/ranking/sort/update',
              submitText: '保存',
              otherUrls: [
                {
                  url: '/ranking/sort/getSchoolList',
                  nameKey: 'data',
                  type: 'souSelect'
                }
              ],
              controls: [
                {
                  key: 'rankingId',
                  label: '院校',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '1',
                  config: {
                    options: [
                      {
                        value: '1',
                        text: 'QS世界大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'collegeId',
                  label: '学校名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  config: {
                    options: 'data',
                    textKey: 'schoolName',
                    valueKey: 'id'
                  }
                },
                {},
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  required: true,
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
        ]
      },
      configTHE: {
        dataUrl: `/ranking/list?rankingId=2`,
        filter: {
          controls: [
            {
              key: 'schoolName',
              label: '学校名称',
              type: 'text'
            },
          ]
        },
        columns: [
          {
            key: 'schoolName',
            title: '学校名',
            width: '130px',
          },
          {
            key: 'sort',
            title: '排名',
            width: '150px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '70px'
          }
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'add',
            text: '编辑介绍',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/desc/update',
            config: {
              color: 'primary',
              title: '编辑介绍',
              initUrl: 'ranking/desc/get?id=2',
              submitUrl: '/ranking/desc/update',
              submitText: '确认',
              controls: [
                {
                  key: 'id',
                  label: '排名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '2',
                  config: {
                    options: [
                      {
                        value: '2',
                        text: 'THE世界大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'logo',
                  label: 'logo',
                  type: 'file',
                  required: true,
                  config: {
                    uploadUrl: '/api/school/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 500
                  }
                },
                {},
                {
                  key: 'rankingName',
                  label: '排名名称',
                  type: 'text',
                  required: true,
                },
                {},
                {
                  key: 'rankingIntroduction',
                  label: '排名简介',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 800, message: '请尽量精简内容，输入800字以内' }
                    ]
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'add',
            text: '新增排名',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/sort/add',
            config: {
              color: 'primary',
              title: '新增排名',
              initUrl: '/ranking/sort/getSchoolList',
              submitUrl: '/ranking/sort/add',
              submitText: '保存',
              controls: [
                {
                  key: 'rankingId',
                  label: '排名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '2',
                  config: {
                    options: [
                      {
                        value: '2',
                        text: 'THE世界大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'collegeId',
                  label: '学校名',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'data',
                    textKey: 'schoolName',
                    valueKey: 'id'
                  }
                },
                {},
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  required: true,
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'add',
            text: '编辑',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/sort/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: '/ranking/sort/get',
              submitUrl: '/ranking/sort/update',
              submitText: '保存',
              otherUrls: [
                {
                  url: '/ranking/sort/getSchoolList',
                  nameKey: 'data',
                  type: 'souSelect'
                }
              ],
              controls: [
                {
                  key: 'rankingId',
                  label: '院校',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '2',
                  config: {
                    options: [
                      {
                        value: '2',
                        text: 'THE世界大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'collegeId',
                  label: '学校名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  config: {
                    options: 'data',
                    textKey: 'schoolName',
                    valueKey: 'id'
                  }
                },
                {},
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  required: true,
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
        ]
      },
      configUSNEWS: {
        dataUrl: `/ranking/list?rankingId=3`,
        filter: {
          controls: [
            {
              key: 'schoolName',
              label: '学校名称',
              type: 'text'
            },
          ]
        },
        columns: [
          {
            key: 'schoolName',
            title: '学校名',
            width: '130px',
          },
          {
            key: 'sort',
            title: '排名',
            width: '150px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '70px'
          }
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'add',
            text: '编辑介绍',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/desc/update',
            config: {
              color: 'primary',
              title: '编辑介绍',
              initUrl: 'ranking/desc/get?id=3',
              submitUrl: '/ranking/desc/update',
              submitText: '确认',
              controls: [
                {
                  key: 'id',
                  label: '排名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '3',
                  config: {
                    options: [
                      {
                        value: '3',
                        text: 'USNEWS美国大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'logo',
                  label: 'logo',
                  type: 'file',
                  required: true,
                  config: {
                    uploadUrl: '/api/school/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 500
                  }
                },
                {},
                {
                  key: 'rankingName',
                  label: '排名名称',
                  type: 'text',
                  required: true,
                },
                {},
                {
                  key: 'rankingIntroduction',
                  label: '排名简介',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 800, message: '请尽量精简内容，输入800字以内' }
                    ]
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'add',
            text: '新增排名',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/sort/add',
            config: {
              color: 'primary',
              title: '新增排名',
              initUrl: '/ranking/sort/getSchoolList',
              submitUrl: '/ranking/sort/add',
              submitText: '保存',
              controls: [
                {
                  key: 'rankingId',
                  label: '排名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '3',
                  config: {
                    options: [
                      {
                        value: '3',
                        text: 'USNEWS美国大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'collegeId',
                  label: '学校名',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'data',
                    textKey: 'schoolName',
                    valueKey: 'id'
                  }
                },
                {},
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  required: true,
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'add',
            text: '编辑',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/sort/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: '/ranking/sort/get',
              submitUrl: '/ranking/sort/update',
              submitText: '保存',
              otherUrls: [
                {
                  url: '/ranking/sort/getSchoolList',
                  nameKey: 'data',
                  type: 'souSelect'
                }
              ],
              controls: [
                {
                  key: 'rankingId',
                  label: '院校',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '3',
                  config: {
                    options: [
                      {
                        value: '3',
                        text: 'USNEWS美国大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'collegeId',
                  label: '学校名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  config: {
                    options: 'data',
                    textKey: 'schoolName',
                    valueKey: 'id'
                  }
                },
                {},
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  required: true,
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
        ]
      },
      configTIMES: {
        dataUrl: `/ranking/list?rankingId=4`,
        filter: {
          controls: [
            {
              key: 'schoolName',
              label: '学校名称',
              type: 'text'
            },
          ]
        },
        columns: [
          {
            key: 'schoolName',
            title: '学校名',
            width: '130px',
          },
          {
            key: 'sort',
            title: '排名',
            width: '150px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '70px'
          }
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'add',
            text: '编辑介绍',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/desc/update',
            config: {
              color: 'primary',
              title: '编辑介绍',
              initUrl: 'ranking/desc/get?id=4',
              submitUrl: '/ranking/desc/update',
              submitText: '确认',
              controls: [
                {
                  key: 'id',
                  label: '排名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '4',
                  config: {
                    options: [
                      {
                        value: '4',
                        text: 'TIMES英国大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'logo',
                  label: 'logo',
                  type: 'file',
                  required: true,
                  config: {
                    uploadUrl: '/api/school/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 500
                  }
                },
                {},
                {
                  key: 'rankingName',
                  label: '排名名称',
                  type: 'text',
                  required: true,
                },
                {},
                {
                  key: 'rankingIntroduction',
                  label: '排名简介',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 800, message: '请尽量精简内容，输入800字以内' }
                    ]
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'add',
            text: '新增排名',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/sort/add',
            config: {
              color: 'primary',
              title: '新增排名',
              initUrl: '/ranking/sort/getSchoolList',
              submitUrl: '/ranking/sort/add',
              submitText: '保存',
              controls: [
                {
                  key: 'rankingId',
                  label: '排名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '4',
                  config: {
                    options: [
                      {
                        value: '4',
                        text: 'TIMES英国大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'collegeId',
                  label: '学校名',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'data',
                    textKey: 'schoolName',
                    valueKey: 'id'
                  }
                },
                {},
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  required: true,
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'add',
            text: '编辑',
            icon: 'plus',
            type: 'form',
            permission: '/ranking/sort/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: '/ranking/sort/get',
              submitUrl: '/ranking/sort/update',
              submitText: '保存',
              otherUrls: [
                {
                  url: '/ranking/sort/getSchoolList',
                  nameKey: 'data',
                  type: 'souSelect'
                }
              ],
              controls: [
                {
                  key: 'rankingId',
                  label: '院校',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: '4',
                  config: {
                    options: [
                      {
                        value: '4',
                        text: 'TIMES英国大学排名',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'collegeId',
                  label: '学校名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  config: {
                    options: 'data',
                    textKey: 'schoolName',
                    valueKey: 'id'
                  }
                },
                {},
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  required: true,
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
        ]
      },
    };
  }
};
</script>

<style lang="less" scoped></style>